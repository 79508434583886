import React from "react";
import { DiscordProfileIcon } from '../Handlers/Imports.jsx';
import { FaHome, FaBirthdayCake, FaMale } from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../Styles/Global.css';

const sentence = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.1,
            staggerChildren: 0.02,
        },
    },
};

const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
};

const About = () => {
    return (
        <>
            <div className="flex flex-col md:flex-row justify-center items-center p-6 md:p-8 rounded-lg mx-auto max-w-5xl mt-12 md:mt-24 space-y-8 md:space-y-0 md:space-x-8">
                <div className="text-white space-y-4 flex-1">
                    <motion.h1
                        className="text-2xl md:text-3xl font-bold text-center"
                        variants={sentence}
                        initial="hidden"
                        animate="visible"
                    >
                        {"Beni tanıyalım!".split("").map((char, index) => (
                            <motion.span key={index} variants={letter}>
                                {char}
                            </motion.span>
                        ))}
                    </motion.h1>

                    <motion.p
                        className="text-md md:text-lg text-center"
                        variants={sentence}
                        initial="hidden"
                        animate="visible"
                    >
                        {"Merhaba. Benim adım ".split("").map((char, index) => (
                            <motion.span key={index} variants={letter}>
                                {char}
                            </motion.span>
                        ))}
                        <motion.span className="text-blue-400 font-semibold" variants={letter}>
                            {"Tyial".split("").map((char, index) => (
                                <motion.span key={index} variants={letter}>
                                    {char}
                                </motion.span>
                            ))}
                        </motion.span>
                        {" Ben ".split("").map((char, index) => (
                            <motion.span key={index} variants={letter}>
                                {char}
                            </motion.span>
                        ))}
                        <motion.span className="text-blue-400 font-semibold" variants={letter}>
                            {" 17 yaşındayım".split("").map((char, index) => (
                                <motion.span key={index} variants={letter}>
                                    {char}
                                </motion.span>
                            ))}
                        </motion.span>
                        {".  Ben".split("").map((char, index) => (
                            <motion.span key={index} variants={letter}>
                                {char}
                            </motion.span>
                        ))}
                        <motion.span className="text-purple-400 font-semibold" variants={letter}>
                            {" lise 3. sınıf öğrencisiyim".split("").map((char, index) => (
                                <motion.span key={index} variants={letter}>
                                    {char}
                                </motion.span>
                            ))}
                        </motion.span>
                        {".".split("").map((char, index) => (
                            <motion.span key={index} variants={letter}>
                                {char}
                            </motion.span>
                        ))}
                        <br />
                        {"Kodlamaya ".split("").map((char, index) => (
                            <motion.span key={index} variants={letter}>
                                {char}
                            </motion.span>
                        ))}
                        <motion.span className="text-blue-400 font-semibold" variants={letter}>
                            {"2014'te başladım".split("").map((char, index) => (
                                <motion.span key={index} variants={letter}>
                                    {char}
                                </motion.span>
                            ))}
                        </motion.span>
                        {" ve bu alanda kendimi sürekli geliştirmeye çalışıyorum.".split("").map((char, index) => (
                            <motion.span key={index} variants={letter}>
                                {char}
                            </motion.span>
                        ))}
                    </motion.p>

                    <div className="flex flex-wrap justify-center space-x-0 md:space-x-4 space-y-4 md:space-y-0 w-full">
                        <motion.div
                            className="bg-gradient-to-br from-blue-500 to-purple-600 border border-white p-4 rounded-xl text-center w-full md:w-40 transition-transform duration-300 hover:bg-gradient-to-br hover:from-purple-600 hover:to-blue-500"
                            whileHover={{ scale: 1.05 }}
                        >
                            <FaHome className="text-white text-lg md:text-xl mx-auto mb-2" />
                            <span className="block text-white">İstanbul</span>
                        </motion.div>
                        <motion.div
                            className="bg-gradient-to-br from-blue-500 to-purple-600 border border-white p-4 rounded-xl text-center w-full md:w-40 transition-transform duration-300 hover:bg-gradient-to-br hover:from-purple-600 hover:to-blue-500"
                            whileHover={{ scale: 1.05 }}
                        >
                            <FaBirthdayCake className="text-white text-lg md:text-xl mx-auto mb-2" />
                            <span className="block text-white">20 Haziran</span>
                        </motion.div>
                        <motion.div
                            className="bg-gradient-to-br from-blue-500 to-purple-600 border border-white p-4 rounded-xl text-center w-full md:w-40 transition-transform duration-300 hover:bg-gradient-to-br hover:from-purple-600 hover:to-blue-500"
                            whileHover={{ scale: 1.05 }}
                        >
                            <FaMale className="text-white text-lg md:text-xl mx-auto mb-2" />
                            <span className="block text-white">Erkek</span>
                        </motion.div>
                    </div>

                </div>

                <motion.div
                    className="flex flex-col items-center"
                    whileHover={{ scale: 1.1 }}
                >
                    <DiscordProfileIcon className="w-24 h-24 md:w-32 md:h-32 rounded-full" />
                    <span className="text-white mt-4">tyial</span>
                </motion.div>
            </div>
        </>
    );
}

export default About;