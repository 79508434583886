import React, { useState, useRef } from 'react';
import axios from 'axios';
import { AiOutlineSend } from 'react-icons/ai';
import { motion } from 'framer-motion';

const GetInTouch = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [status, setStatus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const sectionRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await axios.post('https://formspree.io/f/mjkvbogz', formData, {
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                setStatus('SUCCESS');
                setFormData({ name: '', email: '', message: '' });

                setTimeout(() => {
                    setIsSubmitting(false);
                    setStatus('');
                }, 1000);
            } else {
                setStatus('ERROR');
            }
        } catch (error) {
            console.error('Form gönderilemedi:', error);
            setStatus('ERROR');
            setIsSubmitting(false);
        }
    };

    return (
        <div
            ref={sectionRef}
            className="flex items-center justify-center min-h-screen w-full mt-8"
        >
            <motion.form
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 20 }}
                transition={{ type: 'spring', stiffness: 50, duration: 0.5 }}
                className="p-8 rounded-lg shadow-md w-full max-w-xl mx-auto space-y-4"
                onSubmit={handleSubmit}
            >
                <h2 className="text-4xl font-bold text-white mb-4 text-center sm:text-left">
                    İletişime <span className="text-indigo-500">Geç</span>
                    <span className="ml-2 px-2 py-1 text-xs bg-gray-700 text-white rounded-full">E-posta ile</span>
                </h2>
                <p className="text-gray-400 mt-6 text-center sm:text-left text-sm">support@tyial.dev</p>

                <label className="block text-gray-400 mb-2">Kullanıcı Adı</label>
                <input
                    type="text"
                    name="name"
                    placeholder="Kullanıcı Adı"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full mb-4 p-3 rounded-lg bg-gray-900 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />

                <label className="block text-gray-400 mb-2">E-posta</label>
                <input
                    type="email"
                    name="email"
                    placeholder="E-posta"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full mb-4 p-3 rounded-lg bg-gray-900 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />

                <label className="block text-gray-400 mb-2">Mesaj</label>
                <textarea
                    name="message"
                    placeholder="Mesaj"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    minLength={30}
                    className="w-full mb-4 p-3 rounded-lg bg-gray-900 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    rows="4"
                />

                <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full flex items-center justify-center space-x-2 py-3 rounded-lg transition-colors ${isSubmitting ? 'bg-green-500' : 'bg-indigo-500'} text-white`}
                >
                    {isSubmitting ? (
                        <span>Mesajınız başarıyla gönderildi!</span>
                    ) : (
                        <>
                            <AiOutlineSend />
                            <span>Gönder</span>
                        </>
                    )}
                </button>

                {status === 'ERROR' && (
                    <p className="mt-4 text-red-500 text-center">Bir hata oluştu. Lütfen tekrar deneyin.</p>
                )}
            </motion.form>
        </div>
    );
};

export default GetInTouch;