import React, { useRef } from 'react';
import { FaCode } from "react-icons/fa6";
import { GoServer } from "react-icons/go";
import { VscTools } from "react-icons/vsc";
import { motion } from 'framer-motion';

const MySkills = () => {
    const sectionRef = useRef(null);

    return (
        <div ref={sectionRef} className="text-white py-12 max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-center text-3xl font-bold mb-8">Benim Yeteneklerim</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 max-w-6xl mx-auto">
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ type: 'spring', stiffness: 300 }}
                    whileHover={{ scale: 1.05, borderColor: '#3b82f6' }}
                    className="border border-white/18 rounded-lg p-6 shadow-lg hover:border-blue-500 transform transition-all duration-100"
                >
                    <div className="text-center mb-4">
                        <FaCode className="w-10 h-10 mx-auto mb-2 text-gray-400" />
                        <h3 className="text-xl font-semibold">Frontend</h3>
                    </div>
                    <ul className="text-center text-gray-400 space-y-2">
                        <li>React</li>
                        <li>Next.JS</li>
                        <li>CSS/SASS</li>
                        <li>TailwindCSS</li>
                    </ul>
                </motion.div>

                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ type: 'spring', stiffness: 300 }}
                    whileHover={{ scale: 1.05, borderColor: '#3b82f6' }}
                    className="border border-white/18 rounded-lg p-6 shadow-lg hover:border-blue-500 transform transition-all duration-100"
                >
                    <div className="text-center mb-4">
                        <GoServer className="w-10 h-10 mx-auto mb-2 text-gray-400" />
                        <h3 className="text-xl font-semibold">Backend</h3>
                    </div>
                    <ul className="text-center text-gray-400 space-y-2">
                        <li>Node.js</li>
                        <li>Python</li>
                        <li>Express.JS</li>
                        <li>Mongo.DB</li>
                    </ul>
                </motion.div>

                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ type: 'spring' }}
                    whileHover={{ scale: 1.05, borderColor: '#3b82f6' }}
                    className="border border-white/18 rounded-lg p-6 shadow-lg hover:border-blue-500 transform transition-all duration-100"
                >
                    <div className="text-center mb-4">
                        <VscTools className="w-10 h-10 mx-auto mb-2 text-gray-400" />
                        <h3 className="text-xl font-semibold">Other</h3>
                    </div>
                    <ul className="text-center text-gray-400 space-y-2">
                        <li>UI/UX Design</li>
                        <li>Canva</li>
                        <li>Git</li>
                        <li>AWS</li>
                    </ul>
                </motion.div>
            </div>
        </div>
    );
};

export default MySkills;
