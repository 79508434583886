import React, { useState, useEffect } from 'react';
import { FiArrowUp } from 'react-icons/fi';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <>
            {isVisible && (
                <div className="fixed bottom-8 right-12 z-50">
                    <button
                        onClick={scrollToTop}
                        className="p-3 rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-600 transition duration-300"
                        aria-label="Scroll to top"
                    >
                        <FiArrowUp size={24} />
                    </button>
                </div>
            )}
        </>
    );
};

export default ScrollToTopButton;