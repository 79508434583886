import React from 'react';
import { AiFillHeart } from 'react-icons/ai';

const Footer = () => {
    return (
        <footer className="text-right mr-8">
            <p className="text-white text-sm">
                Web Sitesi Tasarlandı ve Geliştirildi <AiFillHeart className='inline text-red-500' /> Tyial
            </p>
        </footer>
    );
}

export default Footer;
