import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FiArrowUpRight } from 'react-icons/fi';
import firstImageSrc from '../Images/vatanpsikoteknik.com.tr_screenshot.png';
import secondImageSrc from '../Images/synce.app_screenshot.png';
import thirdImageSrc from '../Images/tyial.dev_screenshot.png';

const FeaturedProjects = () => {
    const [hasAnimated, setHasAnimated] = useState(false);

    const projects = [
        { title: 'Vatan Psikoteknik Kursu', imgSrc: firstImageSrc, href: "https://vatanpsikoteknik.com.tr" },
        { title: 'Synce | Discord Bot', imgSrc: secondImageSrc, href: "https://synce.app" },
        { title: 'Portfolyom | tyial.dev', imgSrc: thirdImageSrc, href: "https://tyial.dev" }
    ];

    return (
        <>
            <h2 className="text-white text-center text-3xl font-bold">Çalışmalarım</h2>
            <p className="mt-2 text-center text-white">
                Şu anda bitirmiş olduğum 3 çalışma var.
                <a href="/works" className="text-blue-400 hover:underline"> Diğer çalışmalarımı görmek için tıkla.</a>
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 md:p-8 max-w-full ">
                {projects.map((project, index) => (
                    <motion.div
                        key={index}
                        className="relative w-full text-white p-4 md:p-2 rounded-lg shadow-lg border border-white/18 hover:border-blue-500 duration-300"
                        whileHover={{ scale: 1.05 }}
                        initial={hasAnimated ? {} : { x: -100, opacity: 0 }}
                        animate={hasAnimated ? { x: 0, opacity: 1 } : {}}
                        onViewportEnter={() => setHasAnimated(true)}
                    >
                        <a href={project.href} target="_blank" rel="noopener noreferrer" className="relative block">
                            <img src={project.imgSrc} alt={project.title} className="mb-4 w-full max-w-full max-h-64 object-cover rounded-lg" />

                            <div className="flex justify-between items-center">
                                <h3 className="text-xl md:text-xl font-bold mb-2">{project.title}</h3>

                                <motion.div
                                    className="text-white relative"
                                    style={{ top: '-5px' }}
                                    whileHover={{ scale: 1.2, rotate: 45 }}
                                    transition={{ type: 'spring', stiffness: 300 }}
                                    whileTap={{ scale: 0.9 }}
                                >
                                    <FiArrowUpRight size={32} />
                                </motion.div>
                            </div>
                        </a>
                    </motion.div>
                ))}
            </div>
        </>
    );
};

export default FeaturedProjects;