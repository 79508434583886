import React, { useRef } from 'react';
import { FaReact, FaNodeJs, FaHtml5, FaCss3Alt, FaBootstrap, FaGithub } from 'react-icons/fa';
import { SiTailwindcss, SiJavascript, SiTypescript, SiNextdotjs, SiExpress, SiVercel } from 'react-icons/si';
import { motion } from 'framer-motion';

const technologies = [
    { name: 'Tailwind CSS', icon: <SiTailwindcss className="text-blue-400" /> },
    { name: 'Bootstrap', icon: <FaBootstrap className="text-purple-500" /> },
    { name: 'JavaScript', icon: <SiJavascript className="text-yellow-400" /> },
    { name: 'TypeScript', icon: <SiTypescript className="text-blue-500" /> },
    { name: 'Node.js', icon: <FaNodeJs className="text-green-500" /> },
    { name: 'React.js', icon: <FaReact className="text-blue-400" /> },
    { name: 'Next.js', icon: <SiNextdotjs className="text-gray-300" /> },
    { name: 'Express', icon: <SiExpress className="text-gray-500" /> },
    { name: 'HTML', icon: <FaHtml5 className="text-orange-500" /> },
    { name: 'CSS', icon: <FaCss3Alt className="text-blue-500" /> },
    { name: 'Vercel', icon: <SiVercel className="text-white" /> },
    { name: 'Github', icon: <FaGithub className="text-gray-300" /> },
];

const splitIntoRows = (items, columnsPerRow) => {
    const rows = [];
    for (let i = 0; i < items.length; i += columnsPerRow) {
        rows.push(items.slice(i, i + columnsPerRow));
    }
    return rows;
};

const Technologies = () => {
    const sectionRef = useRef(null);

    const rows = splitIntoRows(technologies, 4);

    return (
        <div ref={sectionRef} className="text-white py-12 px-4 max-w-screen-lg mx-auto sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold mb-4 flex items-center justify-center text-center">
                <span role="img" aria-label="laptop" className="mr-2">💻</span> Kullandığım Teknolojiler
            </h2>
            <p className="text-gray-400 mb-8 text-center">Kullandığım teknolojilerin listesi.</p>
            <div className="space-y-6">
                {rows.map((row, rowIndex) => (
                    <motion.div
                        key={rowIndex}
                        initial="hidden"
                        animate="visible"
                        variants={{
                            hidden: { opacity: 0 },
                            visible: {
                                opacity: 1,
                                transition: {
                                    staggerChildren: 0.1,
                                    delayChildren: 0.1 * rowIndex,
                                },
                            },
                        }}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 max-w-4xl mx-auto"
                    >
                        {row.reverse().map((tech, index) => (
                            <motion.div
                                key={index}
                                initial={{ x: -100, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ type: 'spring' }}
                                whileHover={{ scale: 1.05 }}
                                className="border border-white/18 rounded-lg p-4 shadow-lg flex items-center justify-center space-x-2 hover:border-blue-500 transition-colors duration-300"
                            >
                                <span className="text-3xl">{tech.icon}</span>
                                <span className="text-lg font-semibold">{tech.name}</span>
                            </motion.div>

                        ))}
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Technologies;