import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-python";
import "prismjs/components/prism-csharp";
import "prismjs/components/prism-ruby";
import "prismjs/components/prism-java";
import "prismjs/components/prism-go";
import "prismjs/components/prism-swift";
import "prismjs/components/prism-kotlin";

const codeSnippets = {
  javascript: `class Tyial {
  constructor() {
    this.realName = "Tyial";
    this.age = 17;
    this.nickname = "tyial";
    this.city = "Istanbul, Turkiye";
    this.github = "https://github.com/tyial";
    this.instagram = "https://instagram.com/tyial.dev";
    this.portfolio = "https://tyial.xyz";
  }
}

new Tyial();`,
  typescript: `class Tyial {
  realName: string = "Tyial";
  age: number = 17;
  nickname: string = "tyial";
  city: string = "Istanbul, Turkiye";
  github: string = "https://github.com/tyial";
  instagram: string = "https://instagram.com/tyial.dev";
  portfolio: string = "https://tyial.xyz";

  constructor() {}
}

new Tyial();`,
  python: `class Tyial:
    def __init__(self):
        self.real_name = "Tyial"
        self.age = 17
        self.nickname = "tyial"
        self.city = "Istanbul, Turkiye"
        self.github = "https://github.com/tyial"
        self.instagram = "https://instagram.com/tyial.dev"
        self.portfolio = "https://tyial.xyz"

tyial = Tyial()`,
  csharp: `public class Tyial {
    public string RealName { get; set; } = "Tyial";
    public int Age { get; set; } = 17;
    public string Nickname { get; set; } = "tyial";
    public string City { get; set; } = "Istanbul, Türkiye";
    public string Github { get; set; } = "https://github.com/tyial";
    public string Instagram { get; set; } = "https://instagram.com/tyial.dev";
    public string Portfolio { get; set; } = "https://tyial.xyz";

    public Tyial() {}
}

var tyial = new Tyial();`,
  ruby: `class Tyial
    attr_accessor :real_name, :age, :nickname, :city, :github, :instagram, :portfolio asdsadasda

    def initialize
      @real_name = "Tyial"
      @age = 17
      @nickname = "tyial"
      @city = "Istanbul, Türkiye"
      @github = "https://github.com/tyial"
      @instagram = "https://instagram.com/tyial.dev"
      @portfolio = "https://tyial.xyz"
    end
end

tyial = Tyial.new`,
  java: `public class Tyial {
    private String realName = "Tyial";
    private int age = 17;
    private String nickname = "tyial";
    private String city = "Istanbul, Türkiye";
    private String github = "https://github.com/tyial";
    private String instagram = "https://instagram.com/tyial.dev";
    private String portfolio = "https://tyial.xyz";

    public Tyial() {}
}

Tyial tyial = new Tyial();`,
  go: `package main

import "fmt"

type Tyial struct {
    RealName  string
    Age       int
    Nickname  string
    City      string
    Github    string
    Instagram string
    Portfolio string
}

func main() {
    tyial := Tyial{
        RealName:  "Tyial",
        Age:       17,
        Nickname:  "tyial",
        City:      "Istanbul, Türkiye",
        Github:    "https://github.com/tyial",
        Instagram: "https://instagram.com/tyial.dev",
        Portfolio: "https://tyial.xyz",
    }
    fmt.Println(tyial)
}`,
  swift: `class Tyial {
  var realName: String = "Tyial"
  var age: Int = 17
  var nickname: String = "tyial"
  var city: String = "Istanbul, Türkiye"
  var github: String = "https://github.com/tyial"
  var instagram: String = "https://instagram.com/tyial.dev"
  var portfolio: String = "https://tyial.xyz"

  init() {}
}

let tyial = Tyial()`
};

const languages = [
  { name: "JavaScript", value: "javascript" },
  { name: "TypeScript", value: "typescript" },
  { name: "Python", value: "python" },
  { name: "C#", value: "csharp" },
  { name: "Ruby", value: "ruby" },
  { name: "Java", value: "java" },
  { name: "Go", value: "go" },
  { name: "Swift", value: "swift" }
];

const CodeSnippet = () => {
  const [activeLanguage, setActiveLanguage] = useState("javascript");
  const [isCopied, setIsCopied] = useState(false);
  const [currentSnippet, setCurrentSnippet] = useState("");
  const typingSpeed = 15;

  useEffect(() => {
    let index = 0;
    const fullCode = codeSnippets[activeLanguage];
    const lines = fullCode.split("\n");

    const interval = setInterval(() => {
      if (index < lines.length) {
        const numberedLine = `${lines[index]}`;
        setCurrentSnippet((prev) => prev + (prev ? "\n" : "") + numberedLine);
        index++;
      } else {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, [activeLanguage]);

  const handleTabClick = (lang) => {
    if (activeLanguage === lang) return
    setActiveLanguage(lang);
    setCurrentSnippet("");
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(codeSnippets[activeLanguage]).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="w-full max-w-3xl mx-auto mt-8 border border-white/18 rounded-lg shadow-lg relative sm:mt-4 p-4">
      <div className="flex p-2 border border-white/18 rounded-t-lg overflow-x-auto items-center sm:justify-start lg:justify-center">
        <div className="flex space-x-0.5 flex-nowrap">
          {languages.map((lang) => (
            <div
              key={lang.value}
              onClick={() => handleTabClick(lang.value)}
              className={`cursor-pointer px-4 py-1 text-white ${activeLanguage === lang.value
                ? "rounded border-b-4 border-green-500"
                : ""
                } whitespace-nowrap`}
            >
              {lang.name}
            </div>
          ))}
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          className="p-4 overflow-x-auto sm:px-2"
          key={activeLanguage}
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -50, opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <pre className="text-white">
            <code
              className={`language-${activeLanguage}`}
              dangerouslySetInnerHTML={{
                __html: Prism.highlight(
                  currentSnippet,
                  Prism.languages[activeLanguage],
                  activeLanguage
                ),
              }}
            />
          </pre>
        </motion.div>
      </AnimatePresence>

      <button
        onClick={handleCopyCode}
        className="bg-gray-700 text-white px-8 py-2 mt-4 mx-auto rounded text-sm hover:bg-gray-600 transition duration-300 block sm:mt-2 sm:py-1"
      >
        {isCopied ? "Copied!" : "Copy"}
      </button>
    </div>
  );
};

export default CodeSnippet;