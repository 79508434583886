import React from "react";
import { GetInTouch } from '../Handlers/Imports.jsx';
import '../Styles/Global.css';

const Contact = () => {
    return (
        <>
            <GetInTouch />
        </>
    );
}

export default Contact;