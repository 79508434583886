import React, { useEffect, useState } from 'react';
import axios from 'axios';
import userData from '../Data/User.json';

const DiscordProfileIcon = () => {
    const [user, setUser] = useState(null);
    const userId = userData.userID;

    useEffect(() => {
        const fetchDiscordUser = async () => {
            try {
                const response = await axios.get(`https://api.lanyard.rest/v1/users/${userId}`);
                setUser(response.data.data);
            } catch (error) {
                return
            }
        };

        fetchDiscordUser();
    }, [userId]);

    return (
        <div>
            {user ? (
                <>
                    <div className="rounded-full h-32 w-32">
                        <img
                            src={`https://cdn.discordapp.com/avatars/${user.discord_user.id}/${user.discord_user.avatar}.png?size=256`}
                            alt="Tyial"
                            className="w-full h-full object-cover rounded-xl"
                        />
                    </div>
                </>
            ) : (
                <p className="text-white">Yükleniyor...</p>
            )}
        </div>
    );
};

export default DiscordProfileIcon;