import React from 'react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home, NotFound, Contact, About, Works, ScrollToUp, Navbar } from './Handlers/Imports.jsx';
import './Styles/Global.css';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Loader() {
  const { pathname } = useLocation();

  useEffect(() => {
    NProgress.start();
    window.scrollTo(0, 0);
    NProgress.done();
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Router>
      <Loader />
      <div className="relative">
        <span className="absolute top-0 left-0 shadow-[0_0_1000px_100px_#0ea5e9]"></span>
        <span className="absolute top-0 right-0 shadow-[0_0_1000px_100px_#0ea5e9]"></span>
      </div>
      <Navbar />
      <ScrollToUp />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/works" element={<Works />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  </StrictMode>
);