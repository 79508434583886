import React from "react";
import { AllProjects } from '../Handlers/Imports.jsx';
import '../Styles/Global.css';

const Works = () => {
    return (
        <>
            <div className="mt-12 p-6">
                <AllProjects />
            </div>
        </>
    );
}

export default Works;