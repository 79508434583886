import React, { useEffect, useState, useRef } from 'react';
import { BsArrowRight, BsSpotify } from 'react-icons/bs';
import { TbBrandVscode } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { motion, useInView } from 'framer-motion';
import axios from 'axios';
import Typewriter from 'typewriter-effect';
import userData from '../Data/User.json';

const Hero = () => {
    const [user, setUser] = useState(null);
    const userID = userData.userID;
    const sectionRef = useRef(null);
    const isInView = useInView(sectionRef, { once: true });

    useEffect(() => {
        axios.get(`https://api.lanyard.rest/v1/users/${userID}`)
            .then((response) => {
                setUser(response.data.data);
            })
            .catch((error) => {
                console.error("Kullanıcı verilerini alırken hata:", error);
            });
    }, [userID]);

    const isVisualStudioCodeOpen = user && user.activities
        ? user.activities.some(activity => activity.name === "Visual Studio Code")
        : false;

    let largeImage = '';
    let state = '';
    let details = '';

    if (isVisualStudioCodeOpen) {
        const vscodeActivity = user.activities.find(activity => activity.name === "Visual Studio Code");
        if (vscodeActivity && vscodeActivity.assets) {
            const imageUrl = vscodeActivity.assets.large_image;
            const trimmedUrl = imageUrl.split('https/')[1];
            largeImage = `https://${trimmedUrl}`;
            state = vscodeActivity.state;
            details = vscodeActivity.details;
        }
    }

    return (
        <div className="relative">
            <div ref={sectionRef} className="flex flex-col sm:flex-row items-center justify-center sm:gap-2 space-y-10 sm:space-y-10 w-full mt-20 z-10">
                <motion.div
                    className="sm:w-1/2"
                    initial={{ opacity: 0, x: -100 }}
                    animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="font-sans font-semibold text-gray-400 text-3xl">
                        👋 Selam, ben <span className="text-orange-500">Tyial(Furkan)</span>,
                        <br />
                        <span className="flex items-center gap-1 text-4xl text-orange-500">
                            <BsArrowRight size="48px" color="white" />
                            <Typewriter
                                options={{
                                    strings: ["Web Geliştiricisi", "Discord Bot Geliştiricisi", "Web Tasarımcısı", "Uygulama Geliştiricisi"],
                                    autoStart: true,
                                    loop: true,
                                    delay: 10
                                }}
                            />
                        </span>
                    </h1>

                    <h2 className="font-sans text-base text-gray-200 mt-2">
                        Benim adım Tyial, Discord bot geliştiricisiyim ve aynı zamanda web geliştiricisi ve web tasarımcıyım.
                    </h2>
                    <div className="flex items-center gap-3 mt-4">
                        <Link to="/about">
                            <button className="bg-gray-600 rounded-md text-base text-white font-sans px-5 py-3 flex items-center gap-2 transition duration-300 hover:bg-gray-800">
                                Hakkımda daha fazlasını öğren! <BsArrowRight size="24px" />
                            </button>
                        </Link>
                    </div>
                </motion.div>

                <motion.div
                    className="w-full sm:w-96"
                    align="center"
                    initial={{ opacity: 0, x: 100 }}
                    animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
                    transition={{ duration: 0.4 }}
                >
                    <div className="skew-y-0 sm:skew-y-6 bg-[#111111] w-full sm:w-96 h-72 rounded-lg relative py-5 px-4 overflow-hidden mt-8" align="left">
                        <div className="flex items-center gap-3 w-full">
                            {user ? (
                                <img className="w-24 h-24 rounded-2xl" src={`https://cdn.discordapp.com/avatars/${user.discord_user.id}/${user.discord_user.avatar}.png?size=256`} alt="Avatar" />
                            ) : (
                                <div className="w-24 h-24 rounded-2xl bg-primary-100 animate-pulse" />
                            )}
                            <div>
                                {user ? (
                                    <h1 className="font-sans text-gray-400 font-semibold text-xl">{user.discord_user.username}</h1>
                                ) : (
                                    <div className="bg-primary-100 animate-pulse rounded-lg w-32 h-8"></div>
                                )}
                                <h2 className="font-sans text-gray-200 text-base">Tutkulu bir web geliştiricisi.</h2>
                                <h3 className="font-sans text-white text-sm flex items-center gap-2 mt-2">
                                    <div className="w-4 h-4 rounded-full bg-red-600"></div> Sipariş harici rahatsız etmeyiniz.
                                </h3>
                            </div>
                        </div>
                        <br />
                        <div className="w-full flex justify-between items-center">
                            {isVisualStudioCodeOpen ? (
                                <>
                                    <h1 className="font-sans text-white font-bold text-sm">
                                        Visual Studio Code'da Düzenliyor.
                                    </h1>
                                    <TbBrandVscode size="25px" className="text-blue-500" />
                                </>
                            ) : user && user.spotify ? (
                                <>
                                    <h1 className="font-sans text-white font-bold text-sm">
                                        Spotify'da Müzik Dinliyor.
                                    </h1>
                                    <BsSpotify size="25px" className="text-green-600" />
                                </>
                            ) : (
                                <h1 className="font-sans text-white font-bold text-sm">
                                    Hiç bir aktivite yapmıyor.
                                </h1>
                            )}
                        </div>
                        <div className="flex gap-4 mt-3 w-full">
                            {isVisualStudioCodeOpen ? (
                                <>
                                    <img className="w-24 h-24 rounded-lg" src={largeImage} alt="Visual Studio Code" />
                                    <div>
                                        <h1 className="font-semibold text-white text-base">{details}</h1>
                                        <h2 className="text-gray-300 text-base">{state}</h2>
                                    </div>
                                </>
                            ) : user && user.spotify ? (
                                <>
                                    <img className="w-24 h-24 rounded-lg" src={user.spotify.album_art_url} alt="Album Art" />
                                    <div>
                                        <h1 className="font-semibold text-white text-base">{user.spotify.song}</h1>
                                        <h2 className="text-gray-300 text-base">Şarkıyı {user.spotify.artist} söylüyor.</h2>
                                        <h2 className="text-gray-300 text-base">{user.spotify.album} albümünde.</h2>
                                    </div>
                                </>
                            ) : (
                                <div className="w-24 h-24 rounded-lg bg-primary-100 animate-pulse"></div>
                            )}
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Hero;