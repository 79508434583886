import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { AiFillStar, AiOutlineFork } from "react-icons/ai";
import userData from "../Data/User.json";

const containerVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

const GitHubRepositories = () => {
    const [repositories, setRepositories] = useState([]);
    const [repos, resposForNumbers] = useState([]);
    const sectionRef = useRef(null);

    useEffect(() => {
        const fetchRepositories = async () => {
            try {
                const { githubUserName } = userData;
                const response = await axios.get(
                    `https://api.github.com/users/${githubUserName}/repos`
                );
                const sortedRepos = response.data.sort((a, b) => b.stargazers_count - a.stargazers_count).slice(0, 6);
                const sortedReposForRepoNumbers = response.data.sort((a, b) => b.stargazers_count - a.stargazers_count);

                setRepositories(sortedRepos);
                resposForNumbers(sortedReposForRepoNumbers);
            } catch (error) {
                console.error("GitHub verileri çekilemedi:", error);
            }
        };

        fetchRepositories();
    }, []);

    return (
        <div ref={sectionRef} className="p-5 sm:p-10 text-white mx-auto">
            <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-5 text-center">📖 Github Depolarım</h2>
            <p className="mb-6 sm:mb-8 text-center">
                Şu anda {repos.length} depoya sahibim. Bana destek olmak için yıldız verebilirsiniz!
                <a href={`https://github.com/${userData.githubUserName}`} className="text-blue-400 hover:underline"> Diğer depoları görmek için buraya tıklayın</a>
            </p>

            <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6"
                variants={containerVariants}
                initial="hidden"
                whileInView={"visible"}
                viewport={{ once: true }}
            >
                {repositories.map((repo) => (
                    <motion.div
                        key={repo.id}
                        className="p-4 rounded-lg shadow-md flex flex-col justify-between border border border-white/18 hover:border-blue-500 relative transition-colors duration-300"
                        variants={itemVariants}
                        whileHover={{ scale: 1.05 }}
                    >
                        <a
                            href={repo.html_url}
                            className="text-blue-400 hover:underline text-base sm:text-lg font-semibold truncate max-w-[16ch]"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: "block", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                        >
                            {repo.name}
                        </a>

                        <span className="absolute top-2 right-2 text-xs sm:text-sm bg-gray-700 px-2 py-1 rounded text-gray-300">
                            {repo.private ? "Özel" : "Herkese Açık"}
                        </span>

                        <p className="text-sm sm:text-base mb-4">
                            {repo.description || "Açıklama yok."}
                        </p>
                        <div className="flex items-center justify-between mt-auto">
                            <div className="flex items-center space-x-2">
                                {repo.language ? (
                                    <>
                                        <span className="w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-green-500 inline-block" />
                                        <span className="text-sm">{repo.language}</span>
                                    </>
                                ) : (
                                    <span className="w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-gray-500 inline-block" />
                                )}
                            </div>

                            <div className="flex items-center space-x-4 text-gray-400">
                                <div className="flex items-center space-x-1 text-sm sm:text-base">
                                    <AiFillStar />
                                    <span>{repo.stargazers_count}</span>
                                </div>
                                <div className="flex items-center space-x-1 text-sm sm:text-base">
                                    <AiOutlineFork />
                                    <span>{repo.forks_count}</span>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
};

export default GitHubRepositories;