import React from "react";
import { Hero, MySkills, FeaturedProjects, Technologies, GithubRepositories, CodeSnippet, Footer } from '../Handlers/Imports.jsx';
import '../Styles/Global.css';

const Home = () => {
  return (
    <>
      <Hero />
      <CodeSnippet />
      <MySkills />
      <Technologies />
      <FeaturedProjects />
      <GithubRepositories />
      <Footer />
    </>
  );
}

export default Home;