import React, { useState, useEffect } from 'react';
import { motion, useScroll, AnimatePresence } from 'framer-motion';
import { HiOutlineMenuAlt3, HiX } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { scrollY } = useScroll();
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (scrollY.get() > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        const unsubscribe = scrollY.onChange(handleScroll);
        return () => unsubscribe();
    }, [scrollY]);

    const handleMenuToggle = () => {
        setIsOpen((prev) => !prev);
    };

    const isActive = (path) => location.pathname === path;

    return (
        <motion.nav
            className={`fixed top-0 left-1/2 transform -translate-x-1/2 transition-all duration-300 rounded-xl z-50 
                        ${isScrolled ? 'backdrop-blur-md h-14 w-9/12' : 'backdrop-blur-lg h-16 w-10/12'}`}
            style={{
                border: '1px solid rgba(255, 255, 255, 0.18)',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <div className="container mx-auto px-6 flex justify-between items-center h-full">
                <div className="text-white text-2xl font-bold">
                    <Link to="/">
                        tyial<span className="text-purple-500">.dev</span>
                    </Link>
                </div>

                <ul className="hidden md:flex space-x-6 text-white">
                    <li className={`hover:text-purple-400 transition ${isScrolled ? 'text-sm' : 'text-base'} ${isActive('/') ? 'text-white font-bold' : ''}`}>
                        <Link to="/">Anasayfa</Link>
                    </li>
                    <li className={`hover:text-purple-400 transition ${isScrolled ? 'text-sm' : 'text-base'} ${isActive('/about') ? 'text-white font-bold' : ''}`}>
                        <Link to="/about">Hakkımda</Link>
                    </li>
                    <li className={`hover:text-purple-400 transition ${isScrolled ? 'text-sm' : 'text-base'} ${isActive('/works') ? 'text-white font-bold' : ''}`}>
                        <Link to="/works">Çalışmalarım</Link>
                    </li>
                    <li className={`hover:text-purple-400 transition ${isScrolled ? 'text-sm' : 'text-base'} ${isActive('/contact') ? 'text-white font-bold' : ''}`}>
                        <Link to="/contact">İletişim</Link>
                    </li>
                </ul>

                <div className="md:hidden text-white text-3xl cursor-pointer" onClick={handleMenuToggle}>
                    {isOpen ? <HiX /> : <HiOutlineMenuAlt3 />}
                </div>

                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            className="absolute top-0 left-0 w-[100vw] h-screen bg-black flex flex-col items-start p-8 z-40"
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 100 }}
                            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                        >
                            <div className="text-white text-3xl cursor-pointer mb-6" onClick={handleMenuToggle}>
                                <HiX />
                            </div>
                            <ul className="flex flex-col items-start space-y-6 text-white text-xl">
                                <li className={`hover:text-purple-400 cursor-pointer ${isActive('/') ? 'text-white font-bold' : ''}`}>
                                    <Link to="/" onClick={handleMenuToggle}>Anasayfa</Link>
                                </li>
                                <li className={`hover:text-purple-400 cursor-pointer ${isActive('/about') ? 'text-white font-bold' : ''}`}>
                                    <Link to="/about" onClick={handleMenuToggle}>Hakkımda</Link>
                                </li>
                                <li className={`hover:text-purple-400 cursor-pointer ${isActive('/works') ? 'text-white font-bold' : ''}`}>
                                    <Link to="/works" onClick={handleMenuToggle}>Çalışmalarım</Link>
                                </li>
                                <li className={`hover:text-purple-400 cursor-pointer ${isActive('/contact') ? 'text-white font-bold' : ''}`}>
                                    <Link to="/contact" onClick={handleMenuToggle}>İletişim</Link>
                                </li>
                            </ul>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </motion.nav>
    );
};

export default Navbar;